import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'

import CopyHashLink from "components/CopyHashLink/CopyHashLink";
import Observer from "components/Observer/Observer";

import "./picture.scss"

export type PictureType = {
  children?: JSX.Element,
  descriptions: (
    {type:"text", text:string}
    | {type:"a", text:string, href:string}
  )[],
  href?:string,
  imgs?:React.ReactNode[]
  title: string,
  visualContent?: JSX.Element,
}

export default function Picture(props:{
  children?: JSX.Element,
  odd: boolean,
  picture: PictureType,
}) {
  const [focusedImgIndex, setFocusedImgIndex] = useState(0)
  useEffect(
    () => {
      const timeout = setTimeout(
        () => {
          if(props.picture.imgs) {
            setFocusedImgIndex( (focusedImgIndex + 1)%props.picture.imgs.length )
          }
        },
        5000
      )

      return () => clearTimeout(timeout)
    },
    [props.picture.imgs, focusedImgIndex]
  )

  const linkClassName = props.odd ? "leftTransition" : "rightTransition";
  const imgClassName = props.odd ? "rightTransition" : "leftTransition";
  const getVisualContent = () => {
    //if custom visual content is provided
    if(props.picture.visualContent) {
      return props.picture.visualContent;
    }
    //else if this is an internal link
    else {
      const content = (
        <React.Fragment>
          <div className="imgContainer">
            {props.picture.imgs && props.picture.imgs.map((img, i) =>
              <span className={"staticImg" + (focusedImgIndex===i ? " focused" : "")} key={i}>{img}</span>
            )}
          </div>

          {props.children}
        </React.Fragment>
      )

      if(props.picture.href && props.picture.href.indexOf("http")===-1) {
        return (
          <Link className="linkImage" to={props.picture.href} target="_blank" rel="noopener noreferrer">
            {content}
          </Link>
        );
      }
      //else this is an external link
      return (
        <a className="linkImage" href={props.picture.href} target="_blank" rel="noopener noreferrer">
          {content}
        </a>
      );
    }
  }

  const id = encodeURI(props.picture.title);


  return (
    <div id={id} className="row picture copyHashContainer">
      <div className={"col-sm-12 col-md-6"+(props.odd?"":" order-md-2")}>
        <Observer invisibleClassName={linkClassName} visibleClassName={linkClassName + " visible"}>
          {getVisualContent()}
        </Observer>
      </div>

      <div className={"col-sm-12 col-md-6"+(props.odd?"":" order-md-1")}>
        <Observer invisibleClassName={imgClassName} visibleClassName={imgClassName + " visible"}>
          <div className="columnText columnLabel">
            <p className="itemTitle">{props.picture.title}</p>
            {props.picture.descriptions.map((d, i) =>
              (
                d.type==="a" ?
                <p key={i} className="itemDescription"><a href={d.href} target="_blank" rel="noopener noreferrer">{d.text}</a></p> :
                <p key={i} className="itemDescription">{d.text}</p>
              )
            )}
          </div>
        </Observer>
      </div>

      <CopyHashLink link={id}/>
    </div>
  );
}

import React from "react"

import isBrowser from "constants/isBrowser";

export const LoadingContext = React.createContext({
  isLoaded: true,
  load: (loadingDelay: number) => {},
  loadingDelay: 0,
})

type LoadingContextProviderProps = {children:any}
type LoadingContextProviderState = {
  isLoaded: boolean,
  loadingDelay: number,
}

export class LoadingContextProvider extends React.Component<LoadingContextProviderProps,LoadingContextProviderState> {
  constructor(props:LoadingContextProviderProps) {
    super(props)

    this.state = {
      isLoaded: isBrowser && encodeURI(window.location.hash.slice(1)).length > 0, //load if there is a hash in the url, else don't load yet
      loadingDelay: 0,
    }
  }

  load = (loadingDelay: number) => this.setState({
    isLoaded: true,
    loadingDelay,
  })

  render() {
    return (
      <LoadingContext.Provider value={{
        isLoaded: this.state.isLoaded,
        load: this.load,
        loadingDelay: this.state.loadingDelay,
      }}>
        {this.props.children}
      </LoadingContext.Provider>
    )
  }
}


export const withLoadingContext = (Component:typeof React.Component) => {
  return (props:any) => (
    <LoadingContext.Consumer>
      {value => <Component {...props} isLoaded={value.isLoaded} load={value.load} loadingDelay={value.loadingDelay}/>}
    </LoadingContext.Consumer>
  )
}

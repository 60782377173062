import React from "react"
import ModelSimulator from "model-simulator";
import * as savage from "../../4_savage.json";


export default class ModelSimulatorCanvas extends React.Component {
  constructor(props) {
    super(props)

    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    const simulator = new ModelSimulator({
      canvas: this.canvasRef.current,
      pre_sections: savage.default.pre_sections,
      pre_comments: savage.default.pre_comments,
      models: savage.default.models
    });
    simulator.autoStart(120, true);
  }

  render() {
    return (
      <canvas id="modelSimulatorCanvas" ref={this.canvasRef} width='875' height='560'></canvas>
    )
  }
}

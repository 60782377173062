import React from "react"

import "./conveyorBeltAnimation.scss"
import isBrowser from "../../../constants/isBrowser"

import salmon from "./salmon_nigiri.svg"
import tuna from "./tuna_nigiri.svg"
import tunaRolls from "./tuna_rolls.svg"
import yellowtail from "./yellowtail_nigiri.svg"
import unagi from "./unagi_nigiri.svg"
import alfonsino from "./alfonsino_nigiri.svg"
import mackeral from "./mackerel_nigiri.svg"
import tofu from "./tofu.svg"

type Props = {
  isLoaded: boolean,
}

type State = {
  show: boolean
}

class ConveryBeltAnimation extends React.Component<Props, State> {
  animationTime:number = 500
  state = {
    show: this.props.isLoaded === false, //only show the animation if we are not loaded yet
  }
  stopTimeout?:number
  timeouts: number[] = []

  componentDidUpdate(prevProps:Props) {
    //if we are transitioning from no animation to running the animation
    //we can stop rending the animation once it's done
    if(
      this.props.isLoaded === true
      && !this.stopTimeout
    ) {
      //set a timeout to run once the animation time has passed
      this.stopTimeout = window.setTimeout(
        () => this.setState({show: false}), //don't render anything
        this.animationTime,
      )
    }
  }

  componentWillUnmount() {
    this.timeouts.forEach(clearTimeout)
    clearTimeout(this.stopTimeout)
  }

  calculatePlatePickUpData = (e:React.MouseEvent<HTMLImageElement>) => { //manually estimate how long we need to timeout before putting the plate back
    const CSS_ANIMATION_MS = 30000 //set in CSS
    const SCALE = 1.2 //hard coded pick up scale
    const TRANSLATE = 50 //card coded pick up translate distance

    const height = window.innerHeight
    const width = window.innerWidth
    const cssIconRadius = height * 2 / 25 //set in CSS, half of icon size
    const cssOffsetX = height * 3/20 //set in CSS

    //for performance reasons on mobile, use the event clientX and Y instead of getBoundingClientRect
    let iconCenterX = e.clientX //get the center x of the icon
    let iconCenterY = e.clientY //get the center y of the icon
    //getBoundingClientRect in my testing performs poorly on mobile
    //only use it to get the true center of the icon if we're not on mobile
    if(width > 500) { //if the screen width is big enough, it's probably not a phone
      const boundingRect = (e.target as HTMLElement).getBoundingClientRect() //get the bounding rectangle on the icon
      iconCenterX = boundingRect.left + boundingRect.width/2 //get the center x of the icon
      iconCenterY = boundingRect.top + boundingRect.height/2 //get the center y of the icon
    }
    const sameReturnData = { iconCenterX, iconCenterY }

    const offsetX = iconCenterX - (width / 2) //how far to the left or right from center the icon is
    //how far vertically straight the icons move on the left and right, excluding the bend
    const straightDy = height/2 + cssIconRadius //implied by CSS, since icons are centered, then have a top of 5vh, then move down by half their radius

    const progressY = iconCenterY / straightDy //if <1, the icon is moving on the left or right, else >1 the icon is moving around the bend
    if(progressY < 1) { //if the user clicked on the left or right side
      if(Math.abs(-cssOffsetX - offsetX) <= cssIconRadius) { //if user clicked on the left side
        return {
          ...sameReturnData,
          estimatedWaitTime: (2 - progressY) * CSS_ANIMATION_MS / 2, //wait until the plate is off the screen to make it appear again, simplified from ( 1 - progressY ) * CSS_ANIMATION_MS/2 + CSS_ANIMATION_MS/2
          transform0: ``, //we don't need to do anything here
          transform1: `scale(${SCALE})`, //pick up the plate
          transform2: `translate(-${TRANSLATE}px,0) scale(${SCALE})`, //move the plate away to the left
        }
      }
      else { //else user clicked on the right side
        return {
          ...sameReturnData,
          estimatedWaitTime: progressY * CSS_ANIMATION_MS / 2, //wait until the plate is off the screen to make it appear again
          transform0: `rotate(-180deg)`, //maintain the rotation
          transform1: `scale(${SCALE}) rotate(-180deg)`, //pick up the plate
          transform2: `translate(${TRANSLATE}px,0) scale(${SCALE}) rotate(-180deg)`, //move the plate away to the right
        }
      }
    }

    //else user clicked at the bend
    const offsetY = iconCenterY - straightDy //get boy much vertically into the bend the icon is
    const translateX = TRANSLATE * offsetX / cssOffsetX
    const translateY = TRANSLATE * offsetY / cssOffsetX
    const rotateAngle = -Math.atan(translateX/translateY) * 180 / Math.PI - 90 //we want to maintain the rotation: 0 left, -90 down, -180 right

    return {
      ...sameReturnData,
      estimatedWaitTime: CSS_ANIMATION_MS / 2, //waiting half the animation time is good enough
      transform0: `rotate(${rotateAngle}deg)`, //maintain the rotation
      transform1: `scale(${SCALE}) rotate(${rotateAngle}deg)`, //pick up the plate
      transform2: `translate(${translateX}px,${translateY}px) scale(${SCALE}) rotate(${rotateAngle}deg)`, //move the plate away at the right angle
    }
  }


  clickImg = (e:React.MouseEvent<HTMLImageElement>) => { //attach on mouse down listeners
    //to make it look like we pick up the plate, we
    //1) make the icon disappear, then make it reappear when it is off screen
    //2) clone the img and put it in new wrapper elements
    //3) transition it at the correct angle like it's being pulled away
    //4) delete the clone and wrappers

    //if the img already has the hidden class, the pointer-events:none rule will prevent this event from firing

    const {
      estimatedWaitTime, //estimate when the plate will be off screen to make it appear again for the next cycle
      iconCenterX,
      iconCenterY,
      transform0,
      transform1,
      transform2,
    } = this.calculatePlatePickUpData(e)

    //1)
    const img = (e.target as HTMLElement)
    const imgClone = (img as HTMLElement).cloneNode(true) //make a clone of the img before we add the hidden class
    img.classList.add("hidden") //make it seem like the plate was taken by the user
    this.timeouts.push(
      isBrowser && window.setTimeout(
        () => img.classList.remove("hidden"), //put the plate back on the conveyor belt
        estimatedWaitTime,
      )
    )


    //2)
    const pickedUpPlateContainer = document.createElement("div") //create a plate container element that will set the fixed position and center the inner content
    pickedUpPlateContainer.classList.add("picked-up-plate-container")
    pickedUpPlateContainer.style.top = `${iconCenterY}px`
    pickedUpPlateContainer.style.left = `${iconCenterX}px`

    const pickedUpPlate = document.createElement("div") //create the plate element that will be "animated" using CSS transition
    pickedUpPlate.classList.add("picked-up-plate")
    pickedUpPlate.appendChild(imgClone) //clone the img and append it to the plate

    pickedUpPlateContainer.appendChild(pickedUpPlate) //append the plate to the plate container
    document.getElementById("plates")?.appendChild(pickedUpPlateContainer) //append the plate container to the app


    //3)
    pickedUpPlate.style.transform = transform0 //set the initial transform
    this.timeouts.push(
      isBrowser && window.setTimeout(
        () => pickedUpPlate.style.transform = transform1, //set the first transition style
        30, //wait a little so the initial style takes effect, then we can transition to the first transition style
      )
    )
    this.timeouts.push(
      isBrowser && window.setTimeout(
        () => { //set the second transition style
          pickedUpPlate.style.transform = transform2
          pickedUpPlate.style.opacity = "0"
        },
        500, //0.5s set in CSS
      )
    )


    //4)
    this.timeouts.push(
      isBrowser && window.setTimeout(
        () => pickedUpPlateContainer.remove(), //remove the plate container when our animation is done
        1000, //0.5s * 2 = 1s
      )
    )
  }


  render() {
    if(this.state.show) {
      //we need to add the isLoaded class to run the disappear animation
      //otherwise the painting is too expensive on mobile and the animation is laggy
      return (
        <div>
          <div className={"conveyorBeltAnimation" + (this.props.isLoaded ? " isLoaded" : "")}>
            <div className="icon" style={{animationDelay: "-0.01s"}}><img src={salmon} alt="Salmon Nigiri" style={{transform: "rotate(260deg)"}} onClick={this.clickImg}/></div>
            <div className="icon" style={{animationDelay: "-3.33s"}}><img src={tuna} alt="Tuna Nigiri" style={{transform: "rotate(28deg)"}} onClick={this.clickImg}/></div>
            <div className="icon" style={{animationDelay: "-6.67s"}}><img src={tunaRolls} alt="Tuna Rolls" style={{transform: "rotate(30deg)"}} onClick={this.clickImg}/></div>
            <div className="icon" style={{animationDelay: "-10.00s"}}><img src={yellowtail} alt="Yellowtail Nigiri" style={{transform: "rotate(132deg)"}} onClick={this.clickImg}/></div>
            <div className="icon" style={{animationDelay: "-13.33s"}}><img src={unagi} alt="Unagi Nigiri" style={{transform: "rotate(19deg)"}} onClick={this.clickImg}/></div>
            <div className="icon" style={{animationDelay: "-16.67s"}}><img src={alfonsino} alt="Alfonsino Nigiri" style={{transform: "rotate(181deg)"}} onClick={this.clickImg}/></div>
            <div className="icon" style={{animationDelay: "-20.00s"}}><img src={mackeral} alt="Mackeral Nigiri" style={{transform: "rotate(295deg)"}} onClick={this.clickImg}/></div>
            <div className="icon" style={{animationDelay: "-23.33s"}}><img src={tunaRolls} alt="Tuna Rolls" style={{transform: "rotate(65deg)"}} onClick={this.clickImg}/></div>
            <div className="icon" style={{animationDelay: "-26.67s"}}><img src={tofu} alt="Tofu" style={{transform: "rotate(35deg)"}} onClick={this.clickImg}/></div>
            <div id="plates"></div>
          </div>
        </div>
      )
    }

    return null
  }
}

export default ConveryBeltAnimation

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import "./seeMore.scss"

interface Props {
  array: any[],
  hiddenLength: number,
  mapFunction: (value:any, index:number) => JSX.Element,
  seeLessText?:string,
  seeMoreText?:string,
}

interface State {
  seeMore:boolean
}

export default class SeeMore extends React.Component<Props,State> {
  static defaultProps = {
    seeLessText: "See Less",
    seeMoreText: "See More",
  }

  state = {
    seeMore: false,
  }

  render() {
    const {
      array,
      hiddenLength,
      mapFunction,
      seeLessText,
      seeMoreText,
    } = this.props
    
    const {
      seeMore,
    } = this.state

    return (
      <span>
        {array.slice(0, (seeMore?array.length:hiddenLength)).map(mapFunction)}

        <div className="seeMore" onClick={e => this.setState({seeMore: !seeMore})}>
          {
            seeMore
            ? <span>{seeLessText} <FontAwesomeIcon icon={faAngleUp}/></span>
            : <span>{seeMoreText} <FontAwesomeIcon icon={faAngleDown}/></span>
          }
        </div>
      </span>
    )
  }
}

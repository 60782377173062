import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { StaticImage } from 'gatsby-plugin-image';

import CopyHashLink from "components/CopyHashLink/CopyHashLink";
import Item from "components/Item/Item";
import Loading from "components/Loading/Loading";
import { LoadingContext, LoadingContextProvider } from "components/Loading/LoadingContext";
import Observer from "components/Observer/Observer";
import Picture from "components/Picture/Picture";
import SeeMore from "components/SeeMore/SeeMore";

import smoothScroll from 'utils/smoothScroll';

import { foods, bannerLinks, education, work, dessert, skills, hobbies, mitllWork } from "./landingData"
import './Landing.scss';


const SWITCH_FOOD_INTERVAL_TIME = 3000;


class Landing extends React.Component {
  interval: NodeJS.Timer

  state = {
    foodsHoverIndex: 0,
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  hoverFoods = (i: number, setNewInterval: boolean) => {
    clearInterval(this.interval) //clear any old interval
    this.setState({foodsHoverIndex: i}) //set the new foods hover index
    if(setNewInterval) { //if we want to set a new interval
      this.interval = setInterval(this.incrementHover, SWITCH_FOOD_INTERVAL_TIME) //set the interval
    }
  }

  incrementHover = () => {
    this.setState({foodsHoverIndex: (this.state.foodsHoverIndex+1)%foods.length})
  }


  getContent = () => {
    const {
      foodsHoverIndex,
    } = this.state

    return (
      <React.Fragment>
        <Loading/>

        <header id="about">
          <div id="aboutPicture">
            <StaticImage src="../../img/harry-li.jpg" alt="Harry Li"/>
          </div>

          <div id="aboutText">
            <Observer invisibleClassName="leftTransition" visibleClassName="leftTransition visible">
              <div id="aboutTextCenter">
                <h1 id="name">Harry Li</h1>

                <div className="whiteDivider"></div>

                <p>
                  I am a full stack web developer and data visualization creator at MIT Lincoln Laboratory. I am also a Computer Science Master's student at Tufts University. I love to code solutions for problems I see around me!
                </p>

                <button id="resume"><a href="/lib/harry-li-resume.pdf" target="_blank">Resume</a></button>

                <div id="socialMedia">
                  <a className="iconLink" href="http://www.github.com/harryli0088" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faGithub}/>
                  </a>

                  <a className="iconLink" href="http://www.linkedin.com/in/harry-li-ab5283a8/'" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faLinkedin}/>
                  </a>
                </div>

              </div>
            </Observer>
          </div>
        </header>

        <section id="banner">
          <div id="foodsContainer">
            <div className="row">
              <div id="foods" className="col-sm-12 col-md-8">
                {foods.map((f,i) =>
                  f[0] && <div key={i} className="food" style={{
                    backgroundImage: "url('"+f[0]+"')",
                    opacity: foodsHoverIndex===i ? 1 : 0,
                  }}/>
                )}
              </div>

              <div className="col-sm-12 col-md-4">
                <Observer
                  invisibleClassName="rightTransition"
                  visibleClassName="rightTransition visible"
                  observerCallback={() => {this.interval = setInterval(this.incrementHover, SWITCH_FOOD_INTERVAL_TIME)}}
                >
                  <div id="bannerLinks" onMouseLeave={e => this.hoverFoods(foodsHoverIndex, true)}>
                    {bannerLinks.map((b,i) =>
                      <p
                        key={i}
                        className={"bannerLink" + (foodsHoverIndex===i ? " selected" : "")}
                        onMouseEnter={e => this.hoverFoods(i, false)}
                      >
                        <a href={"#" + b.toLowerCase()} style={{color: "inherit", textDecoration: "none"}}>{b}</a>
                      </p>
                    )}
                  </div>
                </Observer>
              </div>
            </div>
          </div>
        </section>

        <section id="menu">
          <div className="container">
            <Observer invisibleClassName="fadeIn" visibleClassName="fadeIn visible">
              <p id="menuTitle">MENU</p>
            </Observer>

            {menuDivider}

            <div id="appetizers" className="menuSection">
              <div className="copyHashContainer">
                <Observer invisibleClassName="" visibleClassName="visible">
                  <p className="menuHeading">Appetizers</p>
                </Observer>

                <p className="menuSubHeading">Education</p>

                <CopyHashLink link="appetizers"/>
              </div>

              {education.map((e,i) =>
                <Item key={i} item={e}/>
              )}
            </div>

            {menuDivider}

            <div id="entrees" className="menuSection">
              <div className="copyHashContainer">
                <Observer invisibleClassName="" visibleClassName="visible">
                  <p className="menuHeading">Entrees</p>
                </Observer>

                <p className="menuSubHeading">Work and Research</p>

                <CopyHashLink link="entrees"/>
              </div>

              <Observer invisibleClassName="fadeIn" visibleClassName="fadeIn visible">
                <div id={encodeURI("MIT Lincoln Laboratory")} className="item copyHashContainer">
                  <div>
                    <p className="itemTitle">MIT Lincoln Laboratory</p>
                    <p className="itemTime">2018 – Present</p>
                  </div>
                  <p className="itemDescription">Associate Staff</p>
                  <p className="itemDescription">Cyber Security and Information Sciences – Division</p>
                  <p className="itemDescription">&nbsp;&nbsp;&nbsp;Creating interactive visualizations for cyber-defense tools</p>
                  <p className="itemDescription">&nbsp;&nbsp;&nbsp;Creating web tools to help operators understand AI/ML models</p>
                  <p className="itemDescription posterLink" title="Scroll to poster" onClick={e => smoothScroll("Interactive%20Visual%20Uncertainty%20Quantification%20Poster")}>&nbsp;&nbsp;&nbsp;VisSec21 Best Poster: Interactive Visual Uncertainty Quantification</p>
                  <p className="itemDescription posterLink" title="Scroll to poster" onClick={e => smoothScroll("Integrated%20Sensors%20Platform%20Poster")}>&nbsp;&nbsp;&nbsp;AHFE 2019 Poster: Integrated Sensors Platform</p>
                  {/* we don't use an anchor tag with href="#..." because we want a smooth scroll effect */}
                  <p className="itemDescription">Counter-Weapons of Mass Destruction Systems – Group</p>
                  <p className="itemDescription">&nbsp;&nbsp;&nbsp;<a href="https://www.dvidshub.net/news/404735/off-course-mission" target="_blank" rel="noopener noreferrer">Creating simulation systems</a></p>
                  <p className="itemDescription">Clearance eligible</p>

                  <CopyHashLink link="MIT Lincoln Laboratory"/>
                </div>
              </Observer>

              <div>
                {mitllWork.map((d,i) =>
                  <Observer key={i} invisibleClassName="fadeIn" visibleClassName="fadeIn visible">
                    <Picture key={i} picture={d} odd={i%2===1}>
                      {d.children ? d.children : null}
                    </Picture>  
                  </Observer>
                )}
              </div>

              <SeeMore
                array={work}
                hiddenLength={0}
                mapFunction={(w,i) => <Item key={i} item={w}/>}
                seeLessText="Collapse Internships"
                seeMoreText="Show Internships"
              />
            </div>

            {menuDivider}

            <div id="desserts" className="menuSection">
              <div className="copyHashContainer">
                <Observer invisibleClassName="" visibleClassName="visible">
                  <p className="menuHeading">Desserts</p>
                </Observer>

                <p className="menuSubHeading">Projects</p>

                <CopyHashLink link="desserts"/>
              </div>

              <SeeMore
                array={dessert}
                hiddenLength={5}
                mapFunction={(d,i) => (
                  <Picture key={i} picture={d} odd={i%2===1}>
                    {d.children ? d.children : null}
                  </Picture>
                )}
              />
            </div>

            {menuDivider}

            <div id="drinks" className="menuSection">
              <div className="copyHashContainer">
                <Observer invisibleClassName="" visibleClassName="visible">
                  <p className="menuHeading">Drinks</p>
                </Observer>

                <p className="menuSubHeading">Skills</p>

                <CopyHashLink link="drinks"/>
              </div>

              <div className="row">
                {skills.map((s,i) =>
                  <div key={i} className="col-xs-12 col-sm-6 col-md-3">
                    <Observer invisibleClassName="rightTransition" visibleClassName="rightTransition visible">

                      {s.map((sec, j) =>
                        <div key={j} className="columnText">
                          <div>
                            <p className="itemTitle">{sec.title}</p>
                            {sec.items.map((item,k) =>
                              <p key={k} className="itemDescription">{item}</p>
                            )}
                          </div>
                        </div>
                      )}

                    </Observer>
                  </div>
                )}
              </div>
            </div>

            {menuDivider}

            <div id="sides" className="menuSection">
              <div className="copyHashContainer">
                <Observer invisibleClassName="" visibleClassName="visible">
                  <p className="menuHeading">Sides</p>
                </Observer>

                <p className="menuSubHeading">Hobbies</p>

                <CopyHashLink link="sides"/>
              </div>

              <SeeMore
                array={hobbies}
                hiddenLength={3}
                mapFunction={(d,i) => (
                  <Picture key={i} picture={d} odd={i%2===1}>
                    {d.children ? d.children : null}
                  </Picture>
                )}
                seeLessText="See Less"
                seeMoreText="See More"
              />
              <br/>
            </div>
          </div>
        </section>

        <section id="reservations">

          <Observer invisibleClassName="fadeIn" visibleClassName="fadeIn visible">
            <div id="reservationText">
              <p id="reservationsHeading">Reservations</p>
              <p>
                Email: harry.li at ll.mit.edu
              </p>
            </div>
          </Observer>
        </section>

        <footer id="acknowledgements">
          <p>Media icons provided by <a href="https://fontawesome.com/" target="_blank" rel="noopener noreferrer">Font Awesome</a></p>

          <p>Favicon provided by <a href="https://twemoji.twitter.com/" target="_blank" rel="noopener noreferrer">Twemoji</a></p>
        </footer>
      </React.Fragment>
    )
  }


  render() {
    return (
      <LoadingContextProvider>
        <LoadingContext.Consumer>
          {value => {
            return (
              <main id="landingContainer" className={value.isLoaded ? "loaded" : ""}>
                {this.getContent()}
              </main>
            )
          }}
        </LoadingContext.Consumer>
      </LoadingContextProvider>
    );
  }
}

export default Landing


const menuDivider = (
  <Observer invisibleClassName="fadeIn" visibleClassName="fadeIn visible">
    <div className="menuDivider">* * * * *</div>
  </Observer>
)

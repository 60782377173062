import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import './copyHashLink.css';
import isBrowser from '../../constants/isBrowser';


class CopyHashLink extends React.Component {
  onClick = e => {
    const element = document.getElementById("copyHashLink-"+this.props.link); //select the hidden input
    if(element) {
      element.select();
      document.execCommand("copy"); //copy the contents of the hidden input
    }
  }

  render() {
    const copyValue = isBrowser && (window.location.origin + window.location.pathname + "#" + this.props.link)

    return (
      <div className="copyHashLink" title="Copy Hash URL" onClick={this.onClick}>
        <div className="copyHashLinkCover"></div>
        <div className="copyHashLinkBackground"></div>
        <FontAwesomeIcon icon={faLink}/>
        <input id={"copyHashLink-"+this.props.link} value={copyValue} readOnly/>
      </div>
    );
  }
}


export default CopyHashLink;

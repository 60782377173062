import React from 'react'
import { StaticImage } from 'gatsby-plugin-image';

import { ItemType } from "../Item/Item";
import { PictureType } from "../Picture/Picture";

import CitySkylineDemo from "../CitySkylineDemo/CitySkylineDemo";
import ModelSimulatorCanvas from "./ModelSimulatorCanvas";

import ROUTES from "constants/routes"

import gabunomi from "img/gabunomi.svg"
import ramen from "img/ramen.svg"
import sushi from "img/sushi.svg"
import taiyaki from "img/taiyaki.svg"
import takoyaki from "img/takoyaki.svg"
import tempura from "img/tempura.svg"

// import Gabunomi from "./static/Gabunomi.js";
// import Ramen from "./static/Ramen.js";
// import Sushi from "./static/Sushi.js";
// import Takoyaki from "./static/Takoyaki.js";
// import Tempura from "./static/Tempura.js";

export const foods:string[][] = [
  // [<Takoyaki viewBox="0 0 200 200"/>],
  // [<Sushi/>, <Ramen/>],
  // [<Tempura/>],
  // [<Gabunomi/>],
  // [<Tempura/>],
  // [<Tempura/>],
  [takoyaki],
  [sushi,ramen],
  [taiyaki],
  [gabunomi],
  [tempura],
  [],
]

export const bannerLinks:string[] = [
  "Appetizers",
  "Entrees",
  "Desserts",
  "Drinks",
  "Sides",
  "Reservations",
]

export const education:ItemType[] = [
  {
    id: "Tufts University",
    title:"Tufts University",
    status:"Master's in Computer Science",
    time: "Expected May 2023",
    descriptions: [
      "Interested in Explainable AI Research",
    ]
  },
  {
    id: "Massachusetts Institue of Technology Short Course",
    title:"Massachusetts Institue of Technology",
    status:"Non-Degree, Short Course",
    time: "Summer 2020",
    descriptions: [
      "Deep Learning for AI and Computer Vision",
    ]
  },
  {
    title:"Massachusetts Institue of Technology",
    status:"Non-Degree, Advanced Study Program",
    time:"Spring 2019",
    descriptions: [
      "6.036 Introduction to Machine Learning",
      "Grade received: A",
    ]
  },
  {
    title:"Rutgers University",
    status:"Bachelor of Science",
    time:"Graduated May 2018",
    descriptions: [
      "Electrical and Computer Engineering",
      "Economics Minor",
      "Engineering Honors Academy, High Honors",
      "GPA: 3.62",
    ]
  },
];

export const mitllWork:PictureType[] = [
  {
    title: "Interactive Visual Uncertainty Quantification Poster",
    descriptions: [
      {type:"text", text:"Visualization dashboard for encrypted network traffic labels that combines confidence sliders and visualizations to contextualize the model’s uncertainty"},
      {type:"text", text:"Presented at the VisSec21, Best Poster"},
    ],
    href: "https://harryli0088.github.io/interactive-visual-uncertainty-quantification/",
    imgs: [<StaticImage src="../../img/Interactive_Visual_Uncertainty_Quantification_Image.png" alt="Interactive Visual Uncertainty Quantification image"/>]
  },
  {
    title: "Integrated Sensors Platform Poster",
    descriptions: [
      {type:"text", text:"Streamline data collection from physiological sensors to quantitatively improve cyber operations"},
      {type:"text", text:"Presented at the Advanced Human Factors and Ergonomics 2019 conference"},
    ],
    href: "/lib/Harry Li AHFE Instrumentation Poster.pdf",
    imgs: [<StaticImage src="../../img/poster.png" alt="poster"/>]
  },
]

export const work:ItemType[] = [
  {
    title:"Rutgers CPS Lab",
    status: "Research Assistant",
    time:"Spring 2018",
    descriptions: [
      "Built network simulations in NS2, oTCL, and C++ to determine energy consumption of a distributed system",
    ]
  },
  {
    title:"Payfone",
    status: "Intern",
    time:"Fall 2017",
    descriptions: [
      "Improved the Payfone website UI",
      "Wrote Medicare phone API studies in JavaScript and PHP to find benefits-eligible recipients",
    ]
  },
  {
    title:"Vanguard",
    status: "Intern",
    time:"Summer 2017",
    descriptions: [
      "Created a dashboard with D3.js to visualize AWS Cloud migration progress and highlight areas of improvement",
      "Worked with a team of interns to create a crowd-sourced appreciation platform – placed 2nd in the Vanguard 2017 Hackathon – presented to the CEO and CIO",
    ]
  },
  {
    title:"Optum",
    status: "Intern",
    time:"Summer 2016",
    descriptions: [
      "Led a team of interns to create a web application to organize and visualize company server data",
    ]
  },
  {
    title:"Naval Sea Systems Command",
    status: "Intern",
    time:"Summer 2015",
    descriptions: [
      "Researched cyber-secure custom Linux Operating Systems to make minimum-viable OS's",
    ]
  },
]

export const dessert:PictureType[] = [
  {
    title: "StyleGAN2 and Latent Space Discovery Techniques",
    descriptions: [
      {type:"text", text:"Created novel diamond and gemstones datasets"},
      {type:"text", text:"Trained StyleGAN2 to generate realistic diamonds and gemstones"},
      {type:"text", text:"Used latent space discovery techniques to control output features"},
    ],
    href: "https://harryli0088.github.io/CS137-DNN-Project-StyleGAN2-Diamond/game/",
    imgs: [<StaticImage src="../../img/gemstones.png" alt="StyleGAN2 trained to generate gemstone images"/>]
  },
  {
    title: "In-Browser Object Detection with TensorFlow.js",
    descriptions: [
      {type:"text", text:"Created a website that uses SSD Mobilenet to do object detection completely in the browser with TensorFlow.js"},
      {type:"text", text:"Svelte Kit and TypeScript"},
    ],
    href: "https://harryli0088.github.io/tfjs-test/",
    imgs: [<StaticImage src="../../img/tfjs_test.png" alt="TensorFlow.js site screenshot"/>]
  },
  {
    title: "End-to-End Encrypted React - Rust Chat",
    descriptions: [
      {type:"text", text:"Created an end-to-end encrypted messaging system using React and Rust"},
    ],
    href: "https://harryli0088.github.io/rust-react-chat/#/",
    imgs: [<StaticImage src="../../img/react_rust_chat.png" alt="React-Rust chat screenshot"/>]
  },
  {
    title: "Reinforcement Learning Pac-Man",
    descriptions: [
      {type:"text", text:"Trained an RL agent to play Pac-Man"},
      {type:"text", text:"Tree-search state-action encoding"},
      {type:"text", text:"N-Step Semi-Gradient SARSA"},
      {type:"text", text:"Linear function approximation and gradient descent"},
    ],
    href: "https://harryli0088.github.io/reinforcement-learning-pacman/",
    imgs: [<StaticImage src="../../img/pacman_reinforcement_learning.png" alt="pacman reinforcement learning"/>]
  },
  {
    title: "Binary Visualized",
    descriptions: [
      {type:"text", text:"Created a website using odometers to visually and interactively explain binary"},
      {type:"text", text:"Svelte and TypeScript"},
    ],
    href: "https://harryli0088.github.io/binary-visualized/",
    imgs: [<StaticImage src="../../img/binary_visualized.png" alt="binary visualized screenshot"/>]
  },
  {
    title: "Machine Learning: Generative Adversarial Network",
    descriptions: [
      {type:"text", text:"Created a Generative Adversarial Network in PyTorch"},
      {type:"text", text:"Trained on still frames from my dashcam"},
    ],
    href: "https://github.com/harryli0088/dashcam_gan",
    imgs: [<StaticImage src="../../img/dashcamGan.png" alt="dashcam GAN"/>]
  },
  {
    title: "Bearing Rate Graph",
    descriptions: [
      {type:"text", text:"Created a website to explore how submariners visualize the world around them"},
      {type:"text", text:"Made with Svelte"},
    ],
    href: "https://harryli0088.github.io/bearing-rate-graph/",
    imgs: [<StaticImage src="../../img/bearing-rate-graph.png" alt="bearing rate graph"/>]
  },
  {
    title: "CSS Parallax City Skyline",
    descriptions: [{type:"text", text:"Made a parallax Tokyo city skyline website with pure CSS and SVG!"}],
    href: "https://harryli0088.github.io/css-parallax-city-skyline/",
    imgs: [],
    children: (<CitySkylineDemo/>)
  },
  {
    title: "LobbyView",
    descriptions: [
      {type:"text", text:"Created an interactive data visualization website to explore US government lobbying data"},
      {type:"text", text:"Frontend Technical Lead"},
    ],
    href: "https://www.lobbyview.org/",
    imgs: [<StaticImage src="../../img/lobbyview.png" alt="lobbyview"/>]
  },
  // {
  //   title: "Visualizations",
  //   descriptions: [{type:"text", text:"Open-source visualization packages"}],
  //   href: ROUTES.VIZ,
  //   imgs: [<StaticImage src="../../img/stacked-area-chart-d3-react.gif" alt="stacked area chart"/>]
  // },
  {
    title: "Rolling Code Interactive Website",
    descriptions: [
      {type:"text", text:"Learn how car and garage doors are secured using this interactive website!"},
    ],
    href: "https://harryli0088.github.io/rolling-code/",
    imgs: [<StaticImage src="../../img/rollingcode.gif" alt="rolling code"/>]
  },
  {
    title: "Hamming Code Interactive Website",
    descriptions: [
      {type:"text", text:"Learn how to fix bit errors using this interactive website!"},
    ],
    href: "https://harryli0088.github.io/hamming-code/",
    imgs: [<StaticImage src="../../img/hammingcode.gif" alt="hamming code"/>]
  },
  {
    title: "React Native Chinese Handwriting",
    descriptions: [
      {type:"text", text:"Built an experimental Chinese Handwriting app with React Native"},
    ],
    href: "https://github.com/harryli0088/react-native-chinese",
    imgs: [<StaticImage src="../../img/react-native-chinese.gif" alt="react native chinese"/>]
  },
  {
    title: "TendBetter",
    descriptions: [
      {type:"text", text:"All-in-one attendance platform"},
      {type:"a", href:"https://invis.io/P6SPON2UYCT", text:"https://invis.io/P6SPON2UYCT"},
    ],
    href: "http://tendbetter.com",
    imgs: [<StaticImage src="../../img/tendbetter.png" alt="tendbetter"/>]
  },
  {
    title: "Personal Website Workshop",
    descriptions: [{type:"text", text:"How to set up your own personal website, step by step"},],
    href: "https://github.com/harryli0088/personal-website-workshop",
    imgs: [<StaticImage src="../../img/workshop.png" alt="workshop"/>]
  },
  {
    title: "SVG Path Generator",
    descriptions: [{type:"text", text:"Draw with SVG"},],
    href: "https://github.com/harryli0088/svg_path_generator",
    imgs: [<StaticImage src="../../img/takoyaki.svg" alt="takoyaki"/>,<StaticImage src="../../img/sushi.svg" alt="sushi"/>]
  },
  {
    title: "Debt Delta",
    descriptions: [{type:"text", text:"Calculate the simplest way to pay back friends"},],
    href: "https://www.debtdelta.com",
    imgs: [<StaticImage src="../../img/debtdelta.png" alt="bearing rate graph"/>]
  },
  {
    title: "Model Simulator",
    descriptions: [{type:"text", text:"Simulate your fashion walks beat-by-beat"},],
    href: "/walk",
    imgs: [],
    children: (<ModelSimulatorCanvas/>),
  },
  {
    title: "ChinaNite XXIII Reality",
    descriptions: [{type:"text", text:"Co-coordinated a theatrical fashion show"},],
    href: "https://www.dailymotion.com/video/x6kcabu",
    imgs: [<StaticImage src="../../img/reality.jpg" alt="ChinaNite"/>]
  },
]

export const skills = [
  [
    {title:"Front End", items:["React / React Native","Redux","Svelte","TypeScript","D3.js",<span style={{display: "block", width: "50%",marginLeft: "-1em", borderBottom: "1px solid gray"}}/>,"Canvas","CSS/SCSS","SVG",]},
    {title:"Data", items:["GraphQL","HTTP/Fetch","WebSocket",]},
  ],
  [
    {title:"Testing", items:["Jest","Mocha","React Testing Library"]},
    {title:"Back End", items:["Express (Node.js)","Flask (Python)","Meteor (Node.js)"]},
    {title:"Database", items:["MongoDB","PostgreSQL","SQLite",]}
  ],
  [
    {title:"Cloud", items:[
      "AWS",
      "- CLI",
      "- CloudFront",
      "- EC2",
      "- Elastic Beanstalk",
      "- Lambda",
      "- RDS",
      "- Route 53",
      "- S3",
      "Azure Machine Learning",
      "Heroku",
      "MongoDB Atlas"
    ]},
  ],
  [
    {title:"Misc", items:["Apache","Firebase","Git","Google Maps","NumPy","PyTorch","Serverless","Stripe","Tensorflow",]}
  ],
]

export const hobbies:PictureType[] = [
  {
    title: "Sewing - Bomber Jacket",
    descriptions: [],
    href: "https://harryxli.com/#Sewing%20-%20Bomber%20Jacket",
    imgs: [<StaticImage src="../../img/bomberjacket2.jpg" alt="bomber jacket"/>,<StaticImage src="../../img/bomberjacket1.jpg" alt="bomber jacket"/>]
  },
  {
    title: "Layered Paper Art",
    descriptions: [],
    href: "https://harryxli.com/#Layered%Paper%20Art",
    imgs: [
      <StaticImage src="../../img/layered-paper-1.jpg" alt="layered paper"/>,
      <StaticImage src="../../img/layered-paper-2.jpg" alt="layered paper"/>,
      <StaticImage src="../../img/layered-paper-3.jpg" alt="layered paper"/>,
    ]
  },
  {
    title: "Wood Working - Nightstand",
    descriptions: [],
    href: "https://harryxli.com/#Wood%20Working%20-%20Nightstand",
    imgs: [
      <StaticImage src="../../img/nightstand0.jpg" alt="nightstand"/>,
      <StaticImage src="../../img/nightstand1.jpg" alt="nightstand"/>,
      <StaticImage src="../../img/nightstand2.jpg" alt="nightstand"/>,
    ]
  },
  {
    title: "Dress Making",
    descriptions: [],
    href: "http://paporigami.tumblr.com/post/145423332777/second-dress-went-for-a-more-formal-look",
    imgs: [<StaticImage src="../../img/dress.jpg" alt="dress"/>]
  },
  {
    title: "Origami",
    descriptions: [],
    href: "http://paporigami.tumblr.com/post/59228107822/french-fries",
    imgs: [<StaticImage src="../../img/origami.jpg" alt="origami"/>]
  },
  {
    title: "Sweater Making",
    descriptions: [],
    href: "http://paporigami.tumblr.com/post/118073265312/some-of-my-favorite-pusheen-sweaters-ive-made",
    imgs: [<StaticImage src="../../img/sweaters.jpg" alt="sweaters"/>]
  },
  {
    title: "Shirt Painting",
    descriptions: [],
    href: "https://www.instagram.com/p/5xszYHNLVG/",
    imgs: [<StaticImage src="../../img/shirt.jpg" alt="shirt"/>]
  },
]

import React from 'react'
import * as H from "history"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
import ConveyorBeltAnimation from "./ConveyorBeltAnimation/ConveyorBeltAnimation"
import { withLoadingContext } from "components/Loading/LoadingContext"
import isBrowser from '../../constants/isBrowser'
// import OrbitAnimation from "./OrbitAnimation/OrbitAnimation"
// import burger from "./burger.svg"
// import salmon_nigiri from "./salmon_nigiri.svg"
// import tuna_nigiri from "./tuna_nigiri.svg"
// import tuna_rolls from "./tuna_rolls.svg"
import "./loading.scss"
import smoothScroll from '../../utils/smoothScroll'

type Props = {
  isLoaded: boolean,
  load: (loadingDelay: number) => void,
  loadingDelay: number,
  location: H.Location,
}

type State = {
  show: boolean,
}

class Loading extends React.Component<Props,State> {
  animationTime: number
  timeout?:number

  constructor(props:Props) {
    super(props)

    this.state = {
      show: props.isLoaded === false, //only show the animation if we are not loaded yet
    }

    this.animationTime = 1500 + 500 //loading animation time plus plates disappear animation time
  }

  componentDidMount(): void {
    //https://stackoverflow.com/questions/49500339/cant-prevent-touchmove-from-scrolling-window-on-ios
    // window.addEventListener("touchmove", this.load)
    window.addEventListener("wheel", this.load)
  }

  componentDidUpdate(prevProps:Props) {
    //if we are transitioning from no animation to running the animation
    //we can stop rending the animation once it's done
    if(
      this.props.isLoaded === true
      && !this.timeout
    ) {
      //set a timeout to run once the animation time has passed
      this.timeout = isBrowser && window.setTimeout(
        () => this.setState({show: false}), //don't render anything
        this.animationTime,
      )
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout) //just in case this component will be unmounted, clear any outstanding timeouts
    // window.removeEventListener("touchmove", this.load)
    window.removeEventListener("wheel", this.load)
  }

  load = (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.TouchEvent<HTMLDivElement> | Event) => {
    if(this.state.show) {
      e.preventDefault()
      this.props.load(this.animationTime)
      smoothScroll("___gatsby")
    }
  }

  render() {
    //if we still want to render this
    if(this.state.show) {
      return (
        <div id="loading" className={(this.props.isLoaded ? "runAnimation" : "")} onTouchMove={this.load}>
          <ConveyorBeltAnimation isLoaded={this.props.isLoaded}/>

          <div id="welcome">
            <div id="welcomeMessage" className={this.props.isLoaded ? "show" : ""}>Welcome!</div>

            <div id="buttonContainer">
              <button onClick={this.load}><FontAwesomeIcon icon={faAngleUp}/></button>
            </div>
          </div>
        </div>
      )
    }

    return null
  }
}

export default withLoadingContext(Loading)

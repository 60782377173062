import React from 'react'

import CopyHashLink from "components/CopyHashLink/CopyHashLink";
import Observer from "components/Observer/Observer";

export type ItemType = {
  descriptions: string[],
  id?: string,
  status: string,
  time: string,
  title: string,
}

export default function Item(props:{item: ItemType}) {
  const id = encodeURI(props.item.id || props.item.title)

  return (
    <Observer invisibleClassName="fadeIn" visibleClassName="fadeIn visible">
      <div id={id} className="item copyHashContainer">
        <div>
          <p className="itemTitle">{props.item.title}</p>
          <p className="itemTime">{props.item.time}</p>
        </div>

        <p className="itemDescription">{props.item.status}</p>
        {props.item.descriptions.map((d,i) =>
          <p key={i} className="itemDescription">{d}</p>
        )}

        <CopyHashLink link={id}/>
      </div>
    </Observer>
  );
}

import React, {Component} from 'react';
import { withLoadingContext } from "components/Loading/LoadingContext"

class Observer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: false
    }

    this.ref = React.createRef();
    this.observer = null;
  }

  componentDidMount() {
    //if we are already loaded
    if(this.props.isLoaded) {
      this.startObserving()
    }
  }

  componentDidUpdate(prevProps) {
    if(
      prevProps.isLoaded === false
      && this.props.isLoaded === true
    ) { //if we are moving from unloaded to loaded
      setTimeout( //start observing when the loading animation is done
        () => this.startObserving(),
        this.props.loadingDelay,
      )
    }
  }

  startObserving = () => {
    const options = {
      root: document.document,
      threshold: [0, 1]
    }

    this.observer = new IntersectionObserver(this.observerCallback, options);
    this.observer.observe(this.ref.current);
  }

  observerCallback = (entries, observer) => {
    if(
      entries[0].isIntersecting === true
      && this.state.visible === false
    ) {
      this.setState({visible: true});
      if(this.props.observerCallback) this.props.observerCallback(entries, observer)
    }

  }

  render() {
    return (
      <div ref={this.ref} className={this.state.visible ? this.props.visibleClassName : this.props.invisibleClassName} style={{height:"100%"}}>
        {this.props.children}
      </div>
    );
  }
}

export default withLoadingContext(Observer)
